import React from 'react';
import cn from 'classnames';

import type { ActionArgs } from '@customTypes/action';
import { Button, PriceButton } from '../../components';

import styles from './BannerItem.module.scss';

interface BannerItemArgs {
  banner: {
    title: string;
    subtitle?: string;
    textColor: string;
    actions: ActionArgs[];
    ActionSubtitle?: string;
  };
  bannerClassName: string;
  isH1?: boolean;
}

export function BannerItem({ banner, bannerClassName, isH1 }: BannerItemArgs) {
  return (
    <div
      className={cn({
        [styles.metadata]: true,
        [bannerClassName]: !!bannerClassName,
      })}
    >
      {isH1 ? (
        <h1
          className={styles.title}
          style={{ color: banner.textColor }}
          dangerouslySetInnerHTML={{ __html: banner.title }}
        />
      ) : (
        <div
          className={styles.title}
          style={{ color: banner.textColor }}
          dangerouslySetInnerHTML={{ __html: banner.title }}
        />
      )}
      {banner.subtitle && banner.subtitle !== ' ' && (
        <div
          className={styles.subTitle}
          style={{ color: banner.textColor }}
          dangerouslySetInnerHTML={{ __html: banner.subtitle }}
        />
      )}
      {banner.actions[0].actionText !== ' ' && (
        <div className={styles.bannerActions}>
          {banner.actions[0].actionText &&
          banner.actions[0].actionText !== ' ' &&
          banner.actions[0].ActionSubtitle &&
          banner.actions[0].ActionSubtitle !== ' ' ? (
            <PriceButton action={banner.actions[0]} />
          ) : (
            <Button action={banner.actions[0]} />
          )}
        </div>
      )}
    </div>
  );
}
